<template>
  <div class="box_write">
    <!-- { box_write_nosubmenu : isStatementPage } -->
    <button class="link_write" @click="draftDoc">
      <span class="ico_account" />{{ "기안작성" }}
    </button>
  </div>
</template>

<script>
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import VueRouter from "vue-router";

//import menuId from '@/_approval/const/menuId';
import paymentRouterPath from "@/router/paymentRouterPath";

export default {
  name: "GnbExtendTop",
  mixins: [CommLayerMixin],
  computed: {},
  methods: {
    draftDoc() {
      const { isNavigationFailure, NavigationFailureType } = VueRouter;
      // const path = this.isStatementPage ? '/statement/statementWrite' : '/draft';
      const path = paymentRouterPath.PAYMENT_WRITE;
      this.$router.push(path).catch((failure) => {
        if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
        }
      });
    },
  },
};
</script>
<style scoped>
.box_write {
  width: 240px;
  padding: 30px 0 30px;
}
/* .box_write.box_write_nosubmenu{padding-top:40px} */
.box_write .link_write {
  display: block;
  width: 180px;
  height: 50px;
  margin: 0 auto;
  border-radius: 2px;
  font-size: 16px;
  line-height: 50px;
  color: #1a1a1a;
  background: #ffcd00;
  text-decoration: none;
  text-align: center;
}
.box_write .link_write .ico_account {
  display: inline-block;
  width: 14px;
  height: 16px;
  margin: -5px 11px 0 0;
  background-position: -120px -30px;
  vertical-align: middle;
}
</style>
